import React from 'react'





const Projects = () => {
  
  return (
    <div>
        <div className="col s12 m6">
          <div className="card small blue lighten-3">
            <div className="card-content blue-gray-text">
              <span className="card-title">Classy Dinner Adventure Game</span>
              <p>A text-based point-and-click adventure game, using object-oriented programming and DOM manipulation.</p>
              <p>Technologies used: HTML, CSS, JS</p>
              {/* <ul className='row'>
                <li className='col s4'>
                <FontAwesomeIcon icon={faHtml5} size='2x'/>
                </li>
                <li className='col s4'>
                <FontAwesomeIcon icon={faCss3} size='2x'/>
                </li>
                <li className='col s4'>
                <FontAwesomeIcon icon={faJs} size='2x'/>
                </li>
              </ul> */}
            </div>
            <div className="card-action">
              <a className='btn indigo accent-2 col s4' href="https://lind1125.github.io/Classy-Dinner-Adventure-Game">Live Demo</a>
              <a className='btn indigo accent-2 col s4 offset-s4' href="https://github.com/lind1125/Classy-Dinner-Adventure-Game">Github Repo</a>
            </div>
          </div>
        </div>
        <div className="col s12 m6">
          <div className="card small blue lighten-3">
            <div className="card-content blue-gray-text">
              <span className="card-title">Party Planner App</span>
              <p>
                The Party Planner is a full-stack app designed to create a database of food and cocktail recipes, as well as any people you might want to invite. Set your menu and guests with just a few clicks.
              </p>
              <p>Technologies used: NodeJS, Express, EJS, PostreSQL</p>
              {/* <ul className='row'>
                <li className='col s4'>
                <FontAwesomeIcon icon={faNodeJs} size='2x'/>
                </li>
                <li className='col s4'>
                <FontAwesomeIcon icon={faCss3} size='2x'/>
                </li>
                <li className='col s4'>
                <FontAwesomeIcon icon={faJs} size='2x'/>
                </li>
              </ul> */}
            </div>
            <div className="card-action">
              <a className='btn red accent-2 col s6' href='./projects'>Live Demo (Pending)</a>
              <a className='btn indigo accent-2 col s4 offset-s2' href="https://github.com/lind1125/Party-Planner-App">Github Repo</a>
            </div>
          </div>
        </div>
        <div className="col s12 m6">
          <div className="card small blue lighten-3">
            <div className="card-content blue-gray-text">
              <span className="card-title">Fetch</span>
              <p>
                Collaboratively built MERN app to serve as "Tinder for Dog Playdates"
              </p>
              <p>Technologies used: MongoDB, Express, React, Node, Cloudinary API</p>
              {/* <ul className='row'>
                <li className='col s6'>
                <FontAwesomeIcon icon={faReact} size='2x'/>
                </li>
                <li className='col s6'>
                <FontAwesomeIcon icon={faBootstrap} size='2x'/>
                </li>
            </ul> */}
            </div>
            <div className="card-action">
              <a className='btn indigo accent-2 col s4' href="http://fetchappforpals.surge.sh/">Live Demo</a>
              <a className='btn indigo accent-2 col s4 offset-s4' href="https://github.com/lind1125/Fetch-Frontend">Github Repo</a>
            </div>
          </div>
        </div>
        <div className="col s12 m6">
          <div className="card small blue lighten-3">
            <div className="card-content blue-gray-text">
              <span className="card-title">Booxploration</span>
              <p>
                A light app for discovering new books.
              </p>
              <p>Technologies used: React, Bootstrap, Python, Flask, Open Library API</p>
            </div>
            <div className="card-action">
              <a className='btn red accent-2 col s6' href='./projects'>Live Demo (pending)</a>
              <a className='btn indigo accent-2 col s4 offset-s2' href="https://github.com/lind1125/Booxploration_backend">Github Repo</a>
            </div>
          </div>
        </div>
    </div>
)}

export default Projects
