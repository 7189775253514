import React from 'react'

import portrait from '../img/DLind_cropped.jpg'
import Contact from './Contact'

const About = () => {
  return (
    <>
    <div className='container row'>
      <img src={portrait} className='col s4 offset-s4 circle responsive-img' alt='portrait of Dan Linden'/>
      <p className='flow-text col s12'>I am a software developer passionate about seeking new challenges and new learning opportunities. Having started my career as a project coordinator in media post-production, I take a calm and inquisitive approach to finding the best process to achieve the best result. My substantive experience in writing and the performing arts allows me to think quickly and creatively with excellent capacity for communication of ideas to my peers. I value developing positive relationships within a collaborative environment to find where my strengths can best serve a team effort.</p>
      <Contact />
    </div>
    </>
  )
}

export default About
