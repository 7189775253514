import {Route} from 'react-router-dom'

// Components import
import Layout from './components/common/Layout'
import Home from './components/Home'
import About from './components/About'
import Contact from './components/Contact'
import Projects from './components/Projects'

// CSS imports
import './css/App.css';

function App() {
  return (
    <div className="App">
      <Layout>
        <Route path='/' exact component={Home} />
        <Route path='/about' component={About} />
        <Route path='/projects' component={Projects} />
        <Route path='/contact' component={Contact} />
      </Layout>
    </div>
  );
}

export default App;
