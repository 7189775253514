import React from 'react'
import {Link} from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


// css
import '../../css/components/Layout.css'
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'

const Layout = (props) => {
  return (
    <div>
      <nav className='blue lighten-1'>
        <div className='nav-wrapper'>
          <Link to='/' id='home' className='left'>Home</Link>
          <ul className='right'>
            <li>
            </li>
            <li>
              <Link to='/about'>About</Link>
            </li>
            <li>
              <Link to='/projects'>Projects</Link>
            </li>
            <li>
              <a href='https://drive.google.com/file/d/1O2w3iOgkCJ8o9LxHGMm8FG6JNaunBQO4/view'>Resume</a>
            </li>
          </ul>
        </div>
      </nav>
      <div id='parent-div' className='container row'>
        {props.children}
      </div>
      <footer className="page-footer blue lighten-5 black-text">
        <div className="container">
          <div className="row">
            <div className="col s12">
              <div className='row'>
                <a href='https://www.linkedin.com/in/dan-linden/'>
                  <FontAwesomeIcon icon={faLinkedin} size='2x'/>
                </a>
                <a href='https://github.com/lind1125'>
                  <FontAwesomeIcon icon={faGithub} size='2x' />
                </a>
              </div>
            <p>© 2021 Dan Linden</p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Layout
