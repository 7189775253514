import {useState} from 'react'
import emailjs from 'emailjs-com';


const Contact = () => {

  const [submit, setSubmit] = useState(false)
  
  const submitMessage = (e) => {
    e.preventDefault()
    // console.log('target:', e.target)
    emailjs.sendForm('service_892ywoj', 'danlindendotcom_contact', e.target, 'user_5mOqR2gDNkmTY8kuadihu')
    .then((result) => {
        setSubmit(true)
        setTimeout(()=>window.location.reload(), 500)
    }, (error) => {
        console.log(error.text);
    });
  }

  return (
    <div>
      <form onSubmit={submitMessage}>
        <input type="hidden" name="contact_number" />
        <div className="input-field col s12">
          <input 
            id="your_name" 
            type="text" 
            className="validate"
            name= "user_name" />
          <label htmlFor="your_name">Your Name</label>
        </div>
        <div className="input-field col s12">
          <input id="email" type="text" className="validate"
          name= "user_email" />
          <label htmlFor="email">Email</label>
        </div>
        <div className="input-field col s12">
        <textarea id="textarea1" className="materialize-textarea" 
        name = "message"></textarea>
          <label htmlFor="message">Message</label>
        </div>
        <div className="input-field col s12">
        {!submit ?
          <button 
            className='btn blue lighten-1 waves-effect' 
            type='submit'
          >
            Submit
          </button>: <p>Submission Successful!</p>
          }
        </div>
      </form>
    </div>
  )
}

export default Contact
