import React from 'react'
import { Link } from 'react-router-dom'

import portrait from '../img/DLind_cropped.jpg'

const Home = () => {
  return (
    <>
      <img id='profile-image' className='circle col s6' src={portrait} alt='portrait of Dan Linden'/>
      <div className='col s6'>
      <p className='flow-text'>Hi! I'm Dan Linden, a software developer in Minneapolis, Minnesota.</p>
      <Link to='/about' className='btn blue waves-effect'>More About Me</Link>
      </div>
    </>
  )
}

export default Home
